import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { apiEndpoint } from "./prismic-configuration";

// UI
import { Header, Banner } from "./components/UI";

// Pages
import { LandingPage, About, NotFound, Contact } from "./pages";

// Assets
import BannerImg from "./assets/img/banner.png";
import BannerImgLarge from "./assets/img/banner@2x.png";

/**
 * Main application componenet
 */
const App = (props) => {
  const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(apiEndpoint);
  const repoName = repoNameArray[1];

  return (
    <Fragment>
      <Helmet>
        <title>Annálsvert</title>
        <script
          async
          defer
          src={`//static.cdn.prismic.io/prismic.js?repo=${repoName}&new=true`}
        />
      </Helmet>
      <BrowserRouter>
        <Header
          title="Annálsvert"
          links={[
            {
              title: "Um Annálsvert",
              path: "/um",
            },
            {
              title: "Hafa Samband",
              path: "/hafa-samband",
            },
          ]}
        />
        <Banner
          pretitle={"Vísur eftir Glúm Gylfason um"}
          title={"Annálsverð tíðindi á árunum 1540 til 1950"}
          img={BannerImg}
          imglarge={BannerImgLarge}
        />
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/um" component={About} />
          <Route exact path="/hafa-samband" component={Contact} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
};

export default App;
