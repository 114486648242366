// In src/pages/Page.js
import React, { useEffect, useState } from "react";
import Prismic from "prismic-javascript";

import { RichText } from "prismic-reactjs";
import { client, linkResolver } from "../prismic-configuration";
import NotFound from "./NotFound";

// UI
import {
  Container,
  YearNav,
  SearchBar,
  Poem,
  PoemContainer,
  Album,
  Pagination,
} from "../components/UI";

const LandingPage = ({ match }) => {
  const [doc, setDocData] = useState(null);
  const [notFound, toggleNotFound] = useState(false);

  // Pagination and search
  const [century, setCentury] = useState();
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);

  // Search and filters
  const setCenturyFilter = (c) => {
    setPage(1);
    setCentury(c === century ? null : c);
  };

  const setSearchFilter = (s) => {
    setPage(1);
    setCentury();
    setSearch(s);
  };

  // Image viewing
  const [album, setAlbum] = useState([]);
  const [albumIndex, setAlbumIndex] = useState([]);

  const toggleAlbum = (i, images) => {
    if (album.length) {
      setAlbumIndex(null);
      setAlbum([]);
    } else {
      setAlbumIndex(i);
      setAlbum(images);
    }
  };

  const debouncedCentury = useDebounce(century, 250);
  const debouncedSearch = useDebounce(search, 250);
  const debouncedPage = useDebounce(page, 250);
  //   const debouncedSearchTerm = useDebounce(searchTerm, 500);

  // Get the page document from Prismic
  useEffect(() => {
    // TODO: Debounce
    const fetchData = async () => {
      console.log("THIS IS RUNNING");
      // We are using the function to get a document by its UID
      let predicates = debouncedCentury
        ? [
            Prismic.Predicates.gt("my.poem.year", `${debouncedCentury - 1}00`),
            Prismic.Predicates.lt("my.poem.year", `${debouncedCentury}01`),
          ]
        : debouncedSearch
        ? Number(debouncedSearch)
          ? Prismic.Predicates.any("my.poem.year", [Number(debouncedSearch)])
          : Prismic.Predicates.fulltext("document", debouncedSearch)
        : "";

      const query = {
        pageSize: 10,
        orderings: "[my.poem.year]",
        page: debouncedPage || 1,
      };

      const doc = await client.query(predicates, query);
      const result = doc.results;

      if (result) {
        // We use the State hook to save the document
        return setDocData(result.map((p) => p.data));
      } else {
        // Otherwise show an error message
        console.warn(
          "Page document not found. Make sure it exists in your Prismic repository"
        );
        toggleNotFound(true);
      }
    };
    fetchData();
  }, [debouncedCentury, debouncedSearch, debouncedPage]); // Skip the Effect hook if the UID hasn't changed

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, [page]);

  let queueYear = 0;
  if (doc) {
    let poems = doc;
    let centuries = [16, 17, 18, 19, 20];

    return (
      // todo: finish modal and copyright name
      <Container>
        <YearNav
          year={century}
          range={centuries}
          setYear={setCenturyFilter.bind(this)}
        />
        <SearchBar value={search} setSearch={setSearchFilter.bind(this)} />
        <PoemContainer>
          {poems.map((poem, i) => (
            <Poem
              poem={{
                ...poem,
                body: RichText.render(poem.body, linkResolver),
                description: RichText.render(poem.description, linkResolver),
                first:
                  queueYear < poem.year
                    ? (queueYear = poem.year) && true
                    : false,
              }}
              key={poem.year?.toString() + i}
              toggleAlbum={toggleAlbum.bind(this)}
            />
          ))}
        </PoemContainer>
        <Album
          index={albumIndex}
          images={album.map((image) => ({
            ...image,
            credit: RichText.render(image.credit, linkResolver),
          }))}
          setIndex={setAlbumIndex.bind(this)}
          close={(e) => toggleAlbum()}
        />
        <Pagination
          page={page}
          nextPage={(e) => setPage(poems.length === 10 ? page + 1 : page)}
          previousPage={(e) => setPage(page > 1 ? page - 1 : page)}
        />
      </Container>
    );
  } else if (notFound) {
    return <NotFound />;
  }
  return null;
};

// Hook
function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export default LandingPage;
